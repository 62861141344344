import React from "react";
import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta name="google-site-verification" content="AwFdzjBi9WYRkh8VfxS70G-PSnNNfn7pr0QjqiU2_KM" />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Bondgor Cargo",
  keyword:
    "bondgor cargo, cargo, бондгор карго, карго, shipping, logistics",
  desc: "БНХАУ-н бүх хотуудаас Улаанбаатар хүртэл газрын ачааны үйлчилгээ",
};

export default Meta;
