import '../styles/globals.css';
import { ThemeProvider } from 'next-themes';
import Layout from '../components/layout';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { useRouter } from 'next/router';
import { MetaMaskProvider } from 'metamask-react';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { useEffect, useRef } from 'react';
import "../node_modules/leaflet/dist/leaflet.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingProvider } from '../contexts/loaderContext';
import { MainContextProvider } from '../contexts/mainContext';
import { SessionProvider } from 'next-auth/react';
import NextNProgress from "nextjs-progressbar";

function MyApp({ Component, pageProps }) {
	const router = useRouter();
	const pid = router.asPath;
	const scrollRef = useRef({
		scrollPos: 0,
	});
	return (
		<>
			<LoadingProvider>
				<NextNProgress />
				<ToastContainer
					position="top-center"
					autoClose={3000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover={false}
				/>
				<SessionProvider session={pageProps.session} refetchInterval={0}>
					<Meta title="Bondgor Cargo" />
					<Provider store={store}>
						<ThemeProvider enableSystem={true} attribute="class">
							<MetaMaskProvider>
								<UserContext.Provider value={{ scrollRef: scrollRef }}>
									{pid === '/login' ? (
										<Component {...pageProps} />
									) : (
										<MainContextProvider>
											<Layout>
												<Component {...pageProps} />
											</Layout>
										</MainContextProvider>
									)}
								</UserContext.Provider>
							</MetaMaskProvider>
						</ThemeProvider>
					</Provider>
				</SessionProvider>
			</LoadingProvider>
		</>
	);
}

export default MyApp;