import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { error, message } from "../components/Toast/Toast.jsx"

const MainContext = createContext({
  userLoading: false,
  // serverUrl: "https://test.tureeslii.mn",
  serverUrl: "https://bondgorcargo.mn",
});

export const MainContextProvider = (props) => {
  const [userLoading, setUserLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const router = useRouter();

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      setUserLoading(true);
      const res = await fetch("/api/get", {
        method: "post",
        body: JSON.stringify({ url: 'auth/' })
      });
      const data = await res.json();
      if (data && data.error) {
        setCurrentUser(null);
      } else {
        setCurrentUser(data || null);
      }
      setUserLoading(false);
    } catch (err) {
      setUserLoading(false);
      console.error(err, "err");
    }
  };

  const setUserToken = async (data) => {
    if (!data) {
      error("Нууц үг эсвэл утасны дугаар буруу байна.");
      return;
    }
    Cookies.set("authorization", data?.token);
    setCurrentUser({
      id: data?.id,
      mobile: data?.mobile,
      firstname: data?.firstname,
      lastname: data?.lastname,
      roles: data?.roles,
    });
    console.log('data', data);
    router.push("/");
  };

  const removeUserToken = () => {
    Cookies.remove("authorization", { path: "/" });
    setCurrentUser(null);
    message("Амжилттай системээс гарлаа.");
    router.push("/");
  };
  return (
    <MainContext.Provider
      value={{
        currentUser: currentUser,
        setUserToken,
        removeUserToken,
        userLoading,
        // serverUrl: "https://test.tureeslii.mn",
        serverUrl: "https://bondgorcargo.mn",
        getCurrentUser,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContext;