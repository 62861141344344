import { createContext, useState } from 'react';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingContext = createContext(false)

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={[loading, setLoading]}>
      {children}
      {loading &&
        <Backdrop style={{ zIndex: 999 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
