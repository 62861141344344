import Navbar from "./navbar";
import Footer from "./footer";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { MdWarning } from "react-icons/md";
import { BsCheckLg } from "react-icons/bs";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import MainContext from "../contexts/mainContext";

export default function Layout({ children }) {
  const { userLoading, currentUser, removeUserToken } = useContext(MainContext);
  const router = useRouter();
  return (
    <>
      <Navbar />
      <main>{children}</main>
      {!userLoading && currentUser && currentUser.verified == false && (
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 56 }}
        >
          <Toolbar>
            <MdWarning className="h-8 w-8 text-orange pr-2" />
            <Typography variant="body2">
              Та утасны дугаараа баталгаажуулна уу
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <button
              onClick={() => {
                router.push("/profile/verify_mobile");
              }}
              className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md border py-1 px-2"
            >
              <span className="text-green text-sm font-medium tracking-tight flex flex-row items-center justify-center pr-2">
                <BsCheckLg />
              </span>
              <span className="text-white text-sm font-medium tracking-tight flex flex-row items-center justify-center">
                <Typography variant="caption">Баталгаажуулах</Typography>
              </span>
            </button>
          </Toolbar>
        </AppBar>
      )}
      <Footer />
    </>
  );
}
