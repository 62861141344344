const footerMenuList = [
  {
    id: 1,
    title: "Заавар",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/guides/address",
        text: "Хаяг холбох",
      },
      {
        id: 2,
        href: "/guides/track_code",
        text: "Трак код бүртгэх",
      },
    ],
  },
  {
    id: 2,
    title: "Компани",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/contact",
        text: "Холбоо барих",
      },
      {
        id: 2,
        href: "/terms",
        text: "Үйлчилгээний нөхцөл",
      },
    ],
  },
  {
    id: 3,
    title: "Хэрэглэгч",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/profile/order-list",
        text: "Миний захиалга",
      },
      {
        id: 2,
        href: "/profile/user",
        text: "Миний мэдээлэл",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
];

export { footerMenuList, socialIcons };
