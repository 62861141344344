import FactCheckIcon from "@mui/icons-material/FactCheck";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import { useTheme } from "next-themes";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openMblMenu } from "../redux/counterSlice";
import MblNavbar from "./mblNavbar";
import MainContext from "../contexts/mainContext";
import { error, message } from "./Toast/Toast.jsx";

const Navbar = () => {
  const { userLoading, currentUser, removeUserToken } = useContext(MainContext);
  const { theme, setTheme } = useTheme();
  const [scroll, setScroll] = useState(false);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const router = useRouter();
  const pid = router.asPath;

  const handleSticky = function () {
    if (window.scrollY >= 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleTheme = () => {
    if (theme == "dark" && pid === "/home/home_8") {
      setTheme("dark");
    } else if (theme == "dark" && pid !== "/home/home_8") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const TabBarButton = styled(BottomNavigationAction)({
    color: theme == "dark" ? "white" : "black",
    "&.Mui-selected": {
      color: "royalblue",
    },
  });


  const BottomNavigationCustom = styled(BottomNavigation)({
    color: theme == "dark" ? "white" : "black",
    "&.Mui-selected": {
      color: "royalblue",
    },
  });

  // const router = useRouter();

  useEffect(() => {
    if (theme == "system") {
      setTheme("dark");
    }
    if (router.asPath == "/") {
      setValue(0);
    }
    if (router.asPath == "/profile/order-list") {
      setValue(1);
    }
    if (router.asPath == "/mobile/notifications") {
      setValue(2);
    }
    if (router.asPath == "/mobile/settings") {
      setValue(3);
    }
  }, [pid, setTheme, theme]);

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);
  }, []);

  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
        className="visible lg:invisible drop-shadow-xl"
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            if (newValue == 0) {
              router.push("/");
              setValue(newValue);
            }
            if (newValue == 1) {
              if (currentUser) {
                setValue(newValue);
                router.push("/profile/order-list");
              } else error("Та нэвтэрнэ үү");
            }
            if (newValue == 2) {
              if (currentUser) {
                setValue(newValue);
                router.push("/mobile/notifications");
              } else error("Та нэвтэрнэ үү");
            }
            if (newValue == 3) {
              if (currentUser) {
                setValue(newValue);
                router.push("/mobile/settings");
              } else error("Та нэвтэрнэ үү");
            }
          }}
          className="dark:bg-jacarta-800 border-jacarta-100 whitespace-nowrap bg-white drop-shadow-xl"
        >
          <TabBarButton label="Нүүр" icon={<HomeIcon />} />
          <TabBarButton label="Миний захиалга" icon={<FactCheckIcon />} />
          <TabBarButton label="Мэдэгдэл" icon={<NotificationsIcon />} />
          <TabBarButton label="Тохиргоо" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
      <div>
        <header
          className={
            scroll
              ? "js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors js-page-header--is-sticky"
              : "js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors"
          }
        >
          <div className="flex items-center px-6 py-6 xl:px-24 ">
            <Link href="/">
              <a className="shrink-0">
                <img
                  src="/images/logo.png"
                  alt=""
                  className="max-h-7 h-auto dark:hidden"
                />
                <img
                  src="/images/logo_white.png"
                  className="max-h-10 h-auto hidden dark:block"
                />
              </a>
            </Link>
            <MblNavbar theme={handleTheme} className="invisible md:visible" />
          </div>
        </header>
        <div className="ml-auto flex lg:hidden">
          {/* <!-- Dark Mode --> */}
          <button
            className="js-dark-mode-trigger border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => {
              handleTheme();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="fill-jacarta-700 dark-mode-light h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="fill-jacarta-700 dark-mode-dark hidden h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
            </svg>
          </button>

          {/* <!-- Mobile Menu Toggle --> */}
          <button
            className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            aria-label="open mobile menu"
            onClick={() => {
              dispatch(openMblMenu());
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
