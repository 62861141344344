import { toast } from 'react-toastify';

export const message = (text) => {
  toast.success(text);
}

export const error = (text) => {
  toast.error(text);
}


export default { message, error };